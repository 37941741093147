import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { SortDefinition, SortDirection } from '@components/sort/sort-definition';
import { PropertyType } from '@contrail/types';
import { DocumentPropertyDefinition, PanelPropertyTemplate } from '@contrail/document-generation';
import { AuthService } from '@common/auth/auth.service';
import { DocumentGenerationConfig, GridTemplateDimensionsDefinition } from '../document-generator.interfaces';
import { firstValueFrom, Subscription, take, tap } from 'rxjs';
import { FilterConditionType } from '@common/types/filters/filter-condition-types';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { FrameTemplatesSelectors } from '@common/frame-templates/frame-templates-store';
import { FrameTemplate } from '@common/frame-templates/frame-template';
import { ObjectUtil } from '@contrail/util';
import { FrameTemplatesService } from '@common/frame-templates/frame-templates.service';
import { PropertyViewTemplateBuilderComponent } from '../property-view-template-builder/property-view-template-builder.component';
import { DocumentActions } from '../../document/document-store';
import { SideMenuOverlay } from '../../document/document-store/document.state';
import { FilterDefinition, FilterPropertyCriteria, FilterPropertyDefinition } from '@contrail/filters';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { GenerateDocumentUtil } from '../generate-document-util';
import { DocumentGeneratorService } from '../document-generator.service';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature } from '@common/feature-flags/feature-flag';
import { DocumentService } from '../../document/document.service';
import { BoardsActions, BoardsSelectors } from 'src/app/boards/boards-store';
import { ConfirmationBoxService } from '@components/confirmation-box/confirmation-box';
import { ClipboardItemsService } from '@common/clipboard/clipboard-items.service';
import { DocumentGeneratorSourceModalComponent } from '../document-generator-source-modal/document-generator-source-modal.component';
import { AuthSelectors } from '@common/auth/auth-store';

@Component({
  selector: 'app-document-generator-config-editor',
  templateUrl: './document-generator-config-editor.component.html',
  styleUrls: ['./document-generator-config-editor.component.scss'],
})
export class DocumentGeneratorConfigEditorComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('itemCardViewDefinitionBuilder') itemCardViewDefinitionBuilder: PropertyViewTemplateBuilderComponent;
  @ViewChild('framePanelViewDefinitionBuilder') framePanelViewDefinitionBuilder: PropertyViewTemplateBuilderComponent;
  @Input() selectedElement: any;
  public isEditable = true; // False when Lineboard source assortment is clipboard
  public assortmentId;
  public availableProperties: Array<any>;
  public selectedProperties = new Map();
  public selectedSecondaryProperties = [];
  public availablePropertiesForGroup = new Map();
  public gridLayoutOrientation = 'HORIZONTAL';
  public filterDefinition: FilterDefinition;
  public sortProperties: Array<any>;
  public availableViewProperties: Array<DocumentPropertyDefinition>;
  public availablePropertyPanelProperties: Array<DocumentPropertyDefinition>;
  public frameTemplates: Array<any> = [];
  public selectedFrameTemplate: any;
  private propertyMap = {};
  itemChooserLevelSelectionActive = false;
  public regenerateInProgress = false;

  // Properties to show on comonent/cards
  public selectedComponentProperties: Array<DocumentPropertyDefinition>;
  // Sort Definition
  public sortDefinitions: Array<SortDefinition> = [
    {
      direction: SortDirection.ASCENDING,
      propertyLabel: 'Name',
      propertySlug: 'name',
      propertyType: PropertyType.String,
    },
  ];
  // Frame Panel Config
  public includeFramePanel = false;
  public panelPropertyTemplate: PanelPropertyTemplate;
  public defaultComponentPropertyStyle = GenerateDocumentUtil.DEFAULT_COMPONENT_PROPERTY_STYLE;
  public defaultPanelPropertyStyle = GenerateDocumentUtil.DEFAULT_PANEL_PROPERTY_STYLE;
  public gridTemplateOptions = GenerateDocumentUtil.gridTemplateOptions;
  public gridTemplateDimensions: GridTemplateDimensionsDefinition = this.gridTemplateOptions[0];
  public groupMultiSelectInSeparateFrame = false;
  public itemLevel = 'option';
  public isOrgAdmin = false;
  public itemCount = 0;
  public frameCount = 0;
  public loadingCounts = false;
  public assortment: any;
  private assortmentItems: Array<any> = null;
  public loadingAssortmentItems = false;
  public groupings = [];
  private subscriptions: Subscription = new Subscription();
  public documentGenerationConfig: DocumentGenerationConfig;
  public propertyTypeDefaultFilterConditions: any = {};
  sourceValues = [];
  private userId: string;
  typedItemFeatureActive = false;

  constructor(
    private authService: AuthService,
    private store: Store<RootStoreState.State>,
    private frameTemplatesService: FrameTemplatesService,
    private documentGeneratorService: DocumentGeneratorService,
    private documentService: DocumentService,
    private clipboardItemService: ClipboardItemsService,
    private confirmationBoxService: ConfirmationBoxService,
    private matDialog: MatDialog,
  ) {}

  async ngOnInit() {
    const [featureFlags, user] = await Promise.all([
      firstValueFrom(this.store.select(FeatureFlagsSelectors.featureFlags)),
      this.authService.getCurrentUser(),
    ]);
    const flagNamesSet = new Set(featureFlags.map((f) => f.featureName));
    this.itemChooserLevelSelectionActive = flagNamesSet.has(Feature.ITEM_CHOOSER_LEVEL_SELECTION);
    this.typedItemFeatureActive = flagNamesSet.has(Feature.MATERIAL_MVP);
    this.userId = user.id;

    this.subscriptions.add(
      this.store
        .select(BoardsSelectors.currentDocumentGenerationConfig)
        .subscribe(async (documentGenerationConfig: DocumentGenerationConfig) => {
          console.log('documentGenerationConfig: ', documentGenerationConfig);
          this.documentGenerationConfig = ObjectUtil.cloneDeep(documentGenerationConfig);
          const currentAssortmentId = documentGenerationConfig?.assortmentId;
          this.isEditable =
            currentAssortmentId?.includes('clipboard') && currentAssortmentId !== 'clipboard:' + this.userId
              ? false
              : true;
          if (!this.availableProperties) {
            await this.setupInitialConfig();
          }
          if (this.documentGenerationConfig) {
            if (this.documentGenerationConfig.assortmentId !== this.assortmentId) {
              this.assortment = null;
            }
            await this.initOptionsFromExistingConfig();
          }
        }),
    );
    this.store.select(AuthSelectors.currentOrg).subscribe((org) => {
      if (org.orgConfig?.propertyTypeDefaultFilterConditions) {
        this.propertyTypeDefaultFilterConditions = org.orgConfig.propertyTypeDefaultFilterConditions;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async initDefaultPropertyComponentDefinitions() {
    this.selectedComponentProperties = await GenerateDocumentUtil.getDefaultPropertyComponentDefinitions();
  }

  async initFilterDefinition() {
    const definitions = await GenerateDocumentUtil.initFilterAndSortDefinition();
    this.propertyMap = definitions.propertyMap;
    this.filterDefinition = definitions.filterDefinition;
    this.sortProperties = ObjectUtil.cloneDeep(definitions.sortProperties);
  }

  async initOptionsFromExistingConfig() {
    this.selectedProperties = new Map();
    this.groupings = [];
    const selectedPropertySlugs = this.documentGenerationConfig.groupingProperties.map((prop) => prop.slug);
    for (let i = 0; i < selectedPropertySlugs.length; i++) {
      const selectedProp = this.availableProperties.find(
        (prop) => selectedPropertySlugs[i] === prop.propertyDefinition.slug,
      );
      if (selectedProp) {
        this.selectedProperties.set(i, selectedProp);
        this.groupings.push(i);
      }
    }
    if (this.documentGenerationConfig.secondaryGroupingProperties?.length > 0) {
      const selectedSecondaryProperties = this.availableProperties.filter(
        (prop) => this.documentGenerationConfig.secondaryGroupingProperties[0].slug === prop.propertyDefinition.slug,
      );
      this.selectedSecondaryProperties = selectedSecondaryProperties;
      this.selectedSecondaryProperties[0].isSecondaryGroup = true;
    } else {
      this.selectedSecondaryProperties = [];
    }
    if (this.documentGenerationConfig.filterDefinitions) {
      this.filterDefinition.filterCriteria.propertyCriteria = this.documentGenerationConfig.filterDefinitions.map(
        (filter) => {
          return {
            filterConditionType: filter.filterConditionType,
            criteriaValue: filter.criteriaValue,
            filterPropertyDefinition: this.propertyMap[filter.filterPropertyDefinition.slug],
          };
        },
      );
    }
    if (this.documentGenerationConfig.sortProperties) {
      this.sortDefinitions = this.documentGenerationConfig.sortProperties.map((property) => {
        const prop = this.sortProperties.find((p) => p.propertySlug === property.propertySlug);
        return { ...property, propertyLabel: prop.label };
      });
    }
    this.includeFramePanel = this.documentGenerationConfig.includeFramePanel || false;
    this.groupMultiSelectInSeparateFrame = this.documentGenerationConfig.groupMultiSelectInSeparateFrame || false;
    if (this.documentGenerationConfig.frameTemplateId) {
      this.selectedFrameTemplate = { id: this.documentGenerationConfig.frameTemplateId };
    }
    this.gridTemplateDimensions = this.gridTemplateOptions.find(
      (o) =>
        o.gridDimensions.rows === this.documentGenerationConfig.gridLayoutDimensions.gridDimensions.rows &&
        o.gridDimensions.cols === this.documentGenerationConfig.gridLayoutDimensions.gridDimensions.cols,
    );
    this.gridLayoutOrientation = this.documentGenerationConfig.gridLayoutOrientation;

    this.selectedComponentProperties = this.documentGenerationConfig.itemComponentProperties.map((p) => {
      const propertyDefinition = this.availableViewProperties.find(
        (property) => property.slug === p.slug,
      )?.propertyDefinition;
      if (propertyDefinition) {
        return { ...p, propertyDefinition };
      }
      const prop = { ...p };
      if (prop.slug === 'annotation' && !prop.size) {
        prop.size = { width: 80, height: 15 };
      }
      return prop;
    });
    if (this.documentGenerationConfig.panelPropertyTemplate) {
      this.panelPropertyTemplate = {
        properties: this.documentGenerationConfig.panelPropertyTemplate.properties.map((p) => {
          const propertyDefinition = this.availableViewProperties.find(
            (property) => property.slug === p.slug,
          )?.propertyDefinition;
          if (propertyDefinition) {
            return { ...p, propertyDefinition };
          }
          return { ...p };
        }),
      };
    }
    this.itemLevel = this.documentGenerationConfig.itemLevel;
    if (!this.assortment || !this.assortment.assortmentItems) {
      await this.setAssortment(this.documentGenerationConfig.assortmentId);
    }
    this.selectAllAffectedFrames();
    this.setAvailableProperties();
    this.getFrameItemCounts();
  }

  async setAssortment(assortmentId) {
    if (!assortmentId) {
      this.assortmentId = null;
      this.assortmentItems = [];
      this.frameCount = 0;
      this.itemCount = 0;
    } else {
      this.assortmentId = assortmentId;
      this.loadingAssortmentItems = true;
      if (this.assortmentId.includes('clipboard')) {
        const allClipboardItems = await this.clipboardItemService.getClipboardItems();
        let optionItemData = [];
        let familyItemData = [];
        allClipboardItems.forEach((c) => {
          if (c?.item?.roles.includes('option')) {
            optionItemData.push(c);
          } else if (c?.item?.roles.includes('family')) {
            familyItemData.push(c);
          }
        });
        this.assortment = {
          id: this.assortmentId, // clipboard
          optionItemData,
          familyItemData,
        };
      } else {
        this.assortment = await GenerateDocumentUtil.getAssortment(
          this.assortmentId,
          this.itemChooserLevelSelectionActive,
        );
      }
      if (this.itemLevel === 'option') {
        this.assortmentItems = this.assortment.optionItemData;
      } else {
        this.assortmentItems = this.assortment.familyItemData;
      }
      this.itemCount = this.assortmentItems.length;
      this.loadingAssortmentItems = false;
      if (this.selectedProperties.size > 0) {
        this.getFrameItemCounts(true);
      }
    }
  }

  async setupInitialConfig() {
    this.isOrgAdmin = this.authService.isAdmin();
    this.availableProperties = await GenerateDocumentUtil.generateProperties(this.typedItemFeatureActive);
    this.availableViewProperties = this.availableProperties.map((p) => {
      return {
        typeRootSlug: p.typeRootSlug,
        propertyDefinition: p.propertyDefinition,
        slug: p.slug === 'project.name' ? 'projectItem.' + p.propertyDefinition.slug : p.propertyDefinition.slug,
        includeLabel: false,
      };
    });

    this.availablePropertyPanelProperties = this.availableProperties
      .filter((p) => p.propertyDefinition?.propertyLevel !== 'option' && p.propertyDefinition?.slug !== 'optionName')
      .map((p) => {
        return {
          typeRootSlug: p.typeRootSlug,
          propertyDefinition: p.propertyDefinition,
          slug: p.slug === 'project.name' ? 'projectItem.' + p.propertyDefinition.slug : p.propertyDefinition.slug,
          includeLabel: false,
        };
      });

    await this.initFilterDefinition();
    await this.initDefaultPropertyComponentDefinitions();
    this.subscriptions.add(
      this.store
        .select(FrameTemplatesSelectors.frameTemplates)
        .pipe(
          tap((frameTemplates: FrameTemplate[]) => {
            if (frameTemplates.length > 0) {
              // Filter out templates without gridSpaceDefinition
              this.frameTemplates = ObjectUtil.cloneDeep(frameTemplates).filter(
                (template) => template.gridSpaceDefinition,
              );
              this.frameTemplates.unshift({ id: 'default', name: 'Default' });
            }
          }),
        )
        .subscribe(),
    );
    this.subscriptions.add(
      this.documentService.documentElementEvents.subscribe((event) => {
        if (event.eventType === 'selected' && event.element) {
          if (event.element.type === 'frame' && event.element.documentGenerationConfigId) {
            if (event.element.documentGenerationConfigId !== this.documentGenerationConfig.id) {
              this.deselectAllAffectedFrames();
              //this.store.dispatch(BoardsActions.loadCurrentDocumentGenerationConfig({ id: event.element.documentGenerationConfigId }));
            }
            setTimeout(() => {
              this.regenerateInProgress = false;
            }, 1);
          } else {
            this.hide();
          }
        } else if (event.eventType === 'deselect' && !this.regenerateInProgress) {
          this.hide();
        }
      }),
    );
    const groupProperties = this.availableProperties.filter(
      (prop) => prop.propertyDefinition.id !== GenerateDocumentUtil.ITEM_TYPE_NAME_PROP.propertyDefinition.id,
    );
    this.availablePropertiesForGroup.set(-1, ObjectUtil.cloneDeep(groupProperties));
    this.availablePropertiesForGroup.set(0, ObjectUtil.cloneDeep(groupProperties));
  }

  async regenerateLineboard() {
    const lineboardRegenerationWarningShown = JSON.parse(localStorage.getItem('lineboardRegenerationWarningShown'));
    if (!lineboardRegenerationWarningShown) {
      const confirm = await this.confirmationBoxService.open(
        `Regenerate lineboard`,
        `This will re-generate all frames for this lineboard with your new settings. All changes, including added text boxes and shapes will be deleted.
      Do you want to proceed?`,
      );
      if (!confirm) {
        return;
      }
      localStorage.setItem('lineboardRegenerationWarningShown', 'true');
    }

    this.regenerateInProgress = true;
    const generationOptions: any = await this.getGenerationOptions();
    const groupingProperties = Array.from(this.selectedProperties.values()).map((prop) => {
      return { slug: prop.propertyDefinition.slug };
    });
    const secondaryGroupingProperties = this.selectedSecondaryProperties.map((prop) => {
      return { slug: prop.propertyDefinition.slug };
    });
    const generationConfig: DocumentGenerationConfig = await GenerateDocumentUtil.getGenerationConfig(
      this.assortmentId,
      groupingProperties,
      secondaryGroupingProperties,
      this.filterDefinition,
      this.sortDefinitions,
      this.selectedComponentProperties,
      this.gridLayoutOrientation,
      this.gridTemplateDimensions,
      this.groupMultiSelectInSeparateFrame,
      this.includeFramePanel,
      this.panelPropertyTemplate,
      this.selectedFrameTemplate,
      this.itemLevel,
    );
    if (this.documentGenerationConfig.panelPropertyTemplate && !generationConfig.panelPropertyTemplate) {
      generationConfig.panelPropertyTemplate = null;
    }
    generationConfig.id = this.documentGenerationConfig.id;
    if (!generationConfig.includeFramePanel && this.documentGenerationConfig.includeFramePanel) {
      generationConfig.includeFramePanel = false;
    }
    const data = {
      generationOptions,
      generationConfig,
      originalGenerationConfig: ObjectUtil.cloneDeep(this.documentGenerationConfig),
    };
    await this.documentGeneratorService.regenerateFrames(data);
  }

  async selectGroupingProperty(option, index) {
    if (option) {
      this.selectedProperties.set(index, option?.value);
    } else {
      this.selectedProperties.delete(index);
    }
    this.setAvailableProperties();
    this.getFrameItemCounts(true);
    this.resetFilters();
  }

  async selectSecondaryGroupingProperty(option) {
    if (option) {
      this.selectedSecondaryProperties = [];
      const property = ObjectUtil.cloneDeep(option?.value);
      property.isSecondaryGroup = true;
      this.selectedSecondaryProperties.push(property);
    } else {
      this.selectedSecondaryProperties = [];
    }
    this.setAvailableProperties();
    this.getFrameItemCounts(true);
    this.resetFilters();
  }

  public async getFrameItemCounts(refreshSourceValues = false) {
    if (this.itemLevel === 'family') {
      this.assortmentItems = this.assortment.familyItemData;
    } else {
      this.assortmentItems = this.assortment.optionItemData;
    }
    if (this.selectedProperties.size === 0) {
      this.frameCount = 0;
      this.itemCount = this.assortmentItems.length;
    } else {
      this.loadingCounts = true;
      const results = await GenerateDocumentUtil.generateDocumentElements(await this.getGenerationOptions(), {
        x: 0,
        y: 0,
      });
      if (refreshSourceValues) {
        // Was previously using filteredData but was requested to allow for re selecting previously filtered out properties.
        this.sourceValues = this.assortmentItems; //ObjectUtil.cloneDeep(results.filteredData);
      }
      this.itemCount = results.documentElements.filter((de) => de.type === 'component').length;
      this.frameCount = results.documentElements.filter((de) => de.type === 'frame').length;
      this.loadingCounts = false;
    }
  }

  setAvailableProperties() {
    let selectedPropertyIds = Array.from(this.selectedProperties.values()).map((prop) => prop.propertyDefinition.id);
    const selectedSecondaryProperty =
      this.selectedSecondaryProperties.length > 0 ? this.selectedSecondaryProperties[0] : null;
    if (selectedSecondaryProperty) {
      selectedPropertyIds.push(selectedSecondaryProperty.propertyDefinition.id);
    }
    this.availablePropertiesForGroup.forEach((props, key) => {
      const selectedProperties = this.selectedProperties.get(key)
        ? selectedPropertyIds.filter((id) => this.selectedProperties.get(key).propertyDefinition.id !== id)
        : selectedPropertyIds;
      this.availablePropertiesForGroup.set(
        key,
        this.availableProperties?.filter((prop) => !selectedProperties.includes(prop.propertyDefinition.id)),
      );
    });
  }

  public addGrouping() {
    this.groupings.push(this.groupings.length);
    this.availablePropertiesForGroup.set(this.groupings.length - 1, []);
    this.setAvailableProperties();
  }

  public removeGrouping(index) {
    if (this.selectedProperties.get(index)) {
      this.selectedProperties.delete(index);
      // reposition the groupings
      const newSelectedProperties = new Map();
      let i = 0;
      this.selectedProperties.forEach((value, key) => {
        newSelectedProperties.set(i, value);
        i++;
      });
      this.selectedProperties = newSelectedProperties;
      this.getFrameItemCounts(true);
    }
    this.groupings.splice(index, 1);
    this.setAvailableProperties();
    this.resetFilters();
  }

  private resetFilters() {
    Array.from(this.selectedProperties.values()).forEach((prop) => {
      const filterPropertyDefinition: FilterPropertyDefinition = this.propertyMap[prop.propertyDefinition.slug];
      if (
        !this.filterDefinition.filterCriteria.propertyCriteria.find(
          (filterProp) =>
            filterProp.filterPropertyDefinition.slug === prop.typeRootSlug + '.' + prop.propertyDefinition.slug,
        )
      ) {
        const propertyCriteria: FilterPropertyCriteria = {
          filterPropertyDefinition,
          filterConditionType: [PropertyType.SingleSelect, PropertyType.String].includes(
            filterPropertyDefinition.propertyType,
          )
            ? FilterConditionType.IS_ANY_OF
            : FilterConditionType.EQUALS,
        };
        if (filterPropertyDefinition.propertyType === PropertyType.SingleSelect) {
          propertyCriteria.criteriaValue = filterPropertyDefinition.options.map((prop) => prop.value);
        }
        //Check to see if filter for this slug already exists
        const slugExists = this.filterDefinition.filterCriteria.propertyCriteria.some(
          (criteria) => criteria.filterPropertyDefinition.slug === propertyCriteria.filterPropertyDefinition.slug,
        );
        if (!slugExists) {
          this.filterDefinition.filterCriteria.propertyCriteria.push(propertyCriteria);
        }
      }
    });
  }

  private async getGenerationOptions() {
    const properties = Array.from(this.selectedProperties.values()).concat(this.selectedSecondaryProperties);
    const generationOptions: any = {
      assortmentItems: this.assortmentItems,
      groupingProperties: properties,
      gridLayoutOrientation: this.gridLayoutOrientation,
      filterDefinition: this.filterDefinition,
      sortProperties: this.sortDefinitions,
      itemComponentProperties: this.selectedComponentProperties,
      gridLayoutDimensions: this.gridTemplateDimensions,
      groupMultiSelectInSeparateFrame: this.groupMultiSelectInSeparateFrame,
    };
    if (this.includeFramePanel) {
      generationOptions.panelPropertyTemplate = this.panelPropertyTemplate;
    }
    if (this.selectedFrameTemplate && this.selectedFrameTemplate.id !== 'default') {
      this.store
        .select(FrameTemplatesSelectors.getFrameTemplateById(this.selectedFrameTemplate.id))
        .pipe(
          tap((frameTemplate: any) => {
            generationOptions.frameTemplate = ObjectUtil.cloneDeep(frameTemplate);
          }),
        )
        .subscribe();
      if (!generationOptions.frameTemplate.document.elements) {
        generationOptions.frameTemplate = await this.frameTemplatesService.getFrameTemplateById(
          this.selectedFrameTemplate.id,
        );
      }
    }
    return generationOptions;
  }

  handleFilterChange(changes: any) {
    this.filterDefinition.filterCriteria.propertyCriteria = changes.propertyCriteria;
    this.getFrameItemCounts();
  }

  handleSortChange(sortDefinition: any) {
    this.sortDefinitions = sortDefinition.sorts;
  }

  handlePanelPropertyChange(properties) {
    if (properties?.length) {
      this.panelPropertyTemplate = {
        properties,
      };
    } else {
      this.panelPropertyTemplate = null;
    }
  }

  handleItemCardPropertyChange(properties) {
    if (properties?.length) {
      this.selectedComponentProperties = properties;
    } else {
      this.selectedComponentProperties = [];
    }
  }

  handleSetFrameTemplate(template) {
    this.selectedFrameTemplate = template;
    this.getFrameItemCounts();
  }

  hide() {
    this.deselectAllAffectedFrames();
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = '';
    overlay.slug = '';
    overlay.showChooser = false;
    this.store.dispatch(BoardsActions.clearCurrentDocumentGenerationConfig());
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  toggleAssortmentSelector() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '700px';
    dialogConfig.maxHeight = '700px';
    dialogConfig.data = {};
    dialogConfig.disableClose = true;

    const dialogRef = this.matDialog.open(DocumentGeneratorSourceModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async (assortmentId) => {
      if (assortmentId) {
        this.isEditable = true;
        this.loadingCounts = true;
        this.frameCount = 0;
        await this.setAssortment(assortmentId);
        this.getFrameItemCounts();
        this.loadingCounts = false;
      }
    });
  }

  private selectAllAffectedFrames() {
    if (this.documentGenerationConfig) {
      this.documentService.documentElements
        .pipe(
          take(1),
          tap(async (elements) => {
            // Get all frames with the same documentGenerationConfigId
            const frameElements = elements.filter(
              (e) => e.documentGenerationConfigId === this.documentGenerationConfig.id,
            );
            this.documentService.documentGenerationHighlights(frameElements);
          }),
        )
        .subscribe();
    }
  }

  private deselectAllAffectedFrames() {
    if (this.documentGenerationConfig) {
      this.documentService.documentElements
        .pipe(
          take(1),
          tap(async (elements) => {
            // Get all frames with the same documentGenerationConfigId
            const frameElements = elements.filter(
              (e) => e.documentGenerationConfigId === this.documentGenerationConfig.id,
            );
            this.documentService.removeDocumentGenerationHighlights(frameElements);
          }),
        )
        .subscribe();
    }
  }
}
