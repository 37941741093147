<div class="flex justify-between items-center w-[140px] select-none" (click)="preventClose($event)">
  <div class="ml-2">Create</div>
  <div class="flex" [ngClass]="error === true ? 'input error' : 'input'">
    <input data-test="create-typed-items-input" min="1" max="10" type="number" matInput [formControl]="countInput" />
  </div>
  <div class="flex flex-col h-[16px] justify-between -ml-[5px]">
    <div
      class="create-typed-items-count-button increase"
      data-test="create-items-count-increase"
      (click)="increaseCount()"
    >
      <svg viewBox="0 0 10 5">
        <path xmlns="http://www.w3.org/2000/svg" d="M5 0l5 5H0z" fill-rule="evenodd" fill="currentColor"></path>
      </svg>
    </div>
    <div
      class="create-typed-items-count-button decrease"
      data-test="create-items-count-decrease"
      (click)="decreaseCount()"
    >
      <svg viewBox="0 0 10 5">
        <path xmlns="http://www.w3.org/2000/svg" d="M5 5l5-5H0z" fill-rule="evenodd" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <div>
    <button
      class="flex"
      data-test="create-typed-items-button-trigger"
      [matMenuTriggerFor]="menu.childMenu"
      #menuTrigger="matMenuTrigger"
      (mouseover)="openMenu(menuTrigger)"
    >
      <mat-icon class="text-gray-700">arrow_right</mat-icon>
    </button>
  </div>
</div>
<app-type-selector-menu #menu rootTypePath="item" (emitSelectedType)="createTypedItem($event)"></app-type-selector-menu>
