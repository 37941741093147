import { Injectable } from '@angular/core';
import { DocumentComponentService } from '../../document-component/document-component-service';
import { DocumentService } from '../../document.service';
import { DocumentItemService } from '../document-item.service';
import { ComponentEditorService } from '../../component-editor/component-editor.service';
import { DocumentElement } from '@contrail/documents';
import { of, switchMap, take, timer } from 'rxjs';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { setLoading } from '@common/loading-indicator/loading-indicator-store/loading-indicator.actions';
import { DocumentActions } from '../../document-store';
import { UndoRedoService } from '@common/undo-redo/undo-redo-service';

@Injectable({
  providedIn: 'root',
})
export class DocumentCreateItemsService {
  private createItemCount = 1;
  private createItemTypeId: string;
  private itemTypes: any[];
  private itemTypeMap = new Map<string, string>();
  unassignedItemFamilyElements: any[] = [];
  unassignedItemOptionElements: any[] = [];
  constructor(
    private documentComponentService: DocumentComponentService,
    private documentItemService: DocumentItemService,
    private documentService: DocumentService,
    private componentEditorService: ComponentEditorService,
    private store: Store<RootStoreState.State>,
    private undoRedoService: UndoRedoService,
  ) {}

  public setCreateItemCount(count: number) {
    this.createItemCount = count;
  }

  public getRootTypesForItem() {
    return this.itemTypes;
  }

  public async createTypedItem(data: { typeId: string; itemCount: number }) {
    this.createItemCount = data.itemCount;
    this.createItemTypeId = data.typeId;
    this.documentService.setInteractionMode('new_typed_item_family');
  }

  /** Creates a new item family & option and adds the item to the board.
   *
   */
  async createAndAddNewItemOption(elementOptions, itemFamily, data, keepMode = false) {
    this.store.dispatch(setLoading({ loading: true, message: 'Creating new option.' }));
    const itemOption = await this.documentItemService.createEmptyItemOption(itemFamily, data);
    const componentElement = await this.documentComponentService.addComponentElement(itemOption, elementOptions);
    this.store.dispatch(setLoading({ loading: false }));
    if (keepMode) {
      this.documentService.setInteractionMode('new_item_option');
    }
    return componentElement;
  }

  async createAndAddNewItemFamily(elementOptions, keepMode = false) {
    this.store.dispatch(setLoading({ loading: true, message: 'Creating new item.' }));
    const itemFamily = await this.documentItemService.createEmptyItemFamily();
    await this.documentComponentService.addComponentElement(itemFamily, elementOptions);
    this.store.dispatch(setLoading({ loading: false }));
    if (keepMode) {
      this.documentService.setInteractionMode('new_item_family');
    }
  }

  public async createAndAddNewItemFamilies(elementOptions: any, keepMode = false) {
    if (this.createItemCount === 0) {
      return;
    }
    const componentElements = await this.documentComponentService.addComponentElements(
      this.getElementOptionsForMultiCreate(elementOptions, this.createItemCount),
      true,
    );
    if (keepMode) {
      this.documentService.setInteractionMode('new_item_family');
    }
    Object.keys(componentElements).forEach((id) => {
      this.unassignedItemFamilyElements.push(id);
    });
    const assignedElements = [];
    const itemFamilies: any[] = await this.documentItemService.createEmptyItemFamilies(
      this.createItemCount,
      this.createItemTypeId,
    );
    itemFamilies.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    this.store.dispatch(DocumentActions.addDocumentModelEntities({ documentModelEntities: itemFamilies }));
    const componentElementsToAssign = [];
    this.documentService.documentElements.pipe(take(1)).subscribe(async (documentElements) => {
      for (let i = 0; i < itemFamilies.length; i++) {
        componentElementsToAssign.push(
          this.bindItemToElement(
            itemFamilies[i],
            documentElements.find((e) => e.id === this.unassignedItemFamilyElements[i]),
          ),
        );
        assignedElements.push(this.unassignedItemFamilyElements[i]);
      }
      assignedElements.forEach((id) => {
        this.unassignedItemFamilyElements.splice(this.unassignedItemFamilyElements.indexOf(id), 1);
      });

      const actions = await this.componentEditorService.batchUpdateComponentElements(
        componentElementsToAssign,
        true,
        true,
      );
      this.addUndoRedo(actions);
      this.documentService.outlineElements(assignedElements, '#FFA000');
      timer(5000)
        .pipe(switchMap(() => of([])))
        .subscribe(() => this.documentService.removeOutlineElements(assignedElements));
    });
  }

  public async createAndAddNewItemOptions(
    elementOptions: any,
    itemFamily: any = null,
    optionData: any = {},
    keepMode = false,
  ) {
    if (this.createItemCount === 0) {
      return;
    }
    const componentElements = await this.documentComponentService.addComponentElements(
      this.getElementOptionsForMultiCreate(elementOptions, this.createItemCount),
      true,
    );
    if (keepMode) {
      this.documentService.setInteractionMode('new_item_option');
    }
    Object.keys(componentElements).forEach((id) => {
      this.unassignedItemOptionElements.push(id);
    });

    const assignedElements = [];
    const itemOptions: any[] = await this.documentItemService.createEmptyItemOptions(this.createItemCount, itemFamily);
    itemOptions.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    this.store.dispatch(DocumentActions.addDocumentModelEntities({ documentModelEntities: itemOptions }));
    const componentElementsToAssign = [];
    this.documentService.documentElements.pipe(take(1)).subscribe(async (documentElements) => {
      for (let i = 0; i < itemOptions.length; i++) {
        componentElementsToAssign.push(
          this.bindItemToElement(
            itemOptions[i],
            documentElements.find((e) => e.id === this.unassignedItemOptionElements[i]),
          ),
        );
        assignedElements.push(this.unassignedItemOptionElements[i]);
      }
      assignedElements.forEach((id) => {
        this.unassignedItemOptionElements.splice(this.unassignedItemOptionElements.indexOf(id), 1);
      });

      const actions = await this.componentEditorService.batchUpdateComponentElements(
        componentElementsToAssign,
        true,
        true,
      );
      this.addUndoRedo(actions);
      this.documentService.outlineElements(assignedElements, '#FFA000');

      timer(5000)
        .pipe(switchMap(() => of([])))
        .subscribe(() => this.documentService.removeOutlineElements(assignedElements));
    });
  }

  private bindItemToElement(item: any, documentElement: DocumentElement) {
    const bindingChanges = {
      item: `item:${item.id}`,
      viewable: `item:${item.id}`,
      projectItem: `project-item:${item.projectItem.id}`,
    };
    return { documentElement, bindingChanges };
  }

  private getElementOptionsForMultiCreate(options: any, quantity: number) {
    const xMargin = 150;
    const yMargin = 200;
    const columns = 10;
    const rows = Math.floor(quantity / 10) + 1;
    const positionDefinition = options.position;
    let positionX;
    let positionY;
    const elementsToCreate = [];
    for (let r = 0; r < rows; r++) {
      positionY = positionDefinition.y + r * yMargin;
      const remainingColumns = quantity - r * columns;
      const columnCount = remainingColumns < columns ? remainingColumns : 10;
      for (let i = 0; i < columnCount; i++) {
        positionX = positionDefinition.x + i * xMargin;
        elementsToCreate.push({ options: { position: { x: positionX, y: positionY }, style: {} } });
      }
    }
    return elementsToCreate;
  }

  private addUndoRedo(actions: any[]) {
    const undoRedoActions = actions.map((action) => {
      const changeDefinition = Object.assign({}, action.changeDefinition, { changeType: 'ADD_ELEMENT' });
      return {
        actionType: 'document',
        changeDefinition,
        undoChangeDefinition: {
          changeType: 'DELETE_ELEMENT',
          documentId: action.changeDefinition.documentId,
          elementId: action.changeDefinition.elementId,
        },
      };
    });
    this.undoRedoService.addUndo(undoRedoActions);
  }

  private setItemTypeMap() {
    this.itemTypes.forEach((type) => {
      const types = type.typePath.split(':');
      if (types.length > 1) {
        this.itemTypeMap.set(type.id, types[1]);
      }
    });
  }
}
