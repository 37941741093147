import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DocumentCreateItemsService } from '../document-create-items.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-create-typed-items',
  templateUrl: './create-typed-items.component.html',
  styleUrls: ['./create-typed-items.component.scss'],
})
export class DocumentCreateTypedItemsComponent implements OnInit {
  @ViewChild('menuTrigger') trigger: MatMenuTrigger;
  @Input() minValue = 1;
  @Input() maxValue = 10;
  @Input() defaultValue = 1;
  @Output() closeTriggerMenu = new EventEmitter<void>();
  countInput: UntypedFormControl;
  types: any[];
  rootId: string;
  error = false;

  constructor(private documentCreateItemsService: DocumentCreateItemsService) {}

  ngOnInit() {
    this.countInput = new UntypedFormControl(this.defaultValue);
    this.countInput.valueChanges.subscribe((value) => {
      if (value < this.minValue || value > this.maxValue) {
        this.error = true;
      } else {
        this.error = false;
      }
    });
  }

  createTypedItem(typeId: string) {
    this.closeTriggerMenu.emit();
    this.documentCreateItemsService.createTypedItem({ typeId, itemCount: this.countInput.value });
    this.countInput.setValue(this.defaultValue);
  }

  openMenu(menuTrigger: MatMenuTrigger) {
    if (!this.error) {
      menuTrigger.openMenu();
    }
  }

  preventClose(event: Event) {
    event.stopPropagation();
  }

  increaseCount() {
    if (this.countInput.value < this.maxValue) {
      this.countInput.setValue(this.countInput.value + 1);
    }
  }

  decreaseCount() {
    if (this.countInput.value > this.minValue) {
      this.countInput.setValue(this.countInput.value - 1);
    }
  }
}
